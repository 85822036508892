<div class="header">
  <!-- Router link here is default ["/"] -->

  @if ((configService.logInState$ | async) && environment.siteList !== 'prod') {
    <spb-logo [routePath]="['home']"></spb-logo>
  } @else {
    <spb-logo [routePath]="['home']" title="Tjänsteportalen"></spb-logo>
  }
  @if ((configService.logInState$ | async)) {
    <div class="menu">
      <span>{{ (configService.logInState$ | async)?.name }}</span>
      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  }
</div>

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
