import {Component} from '@angular/core'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatButton} from '@angular/material/button'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe} from '@angular/common'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [ThemeModule, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, AsyncPipe]
})
export class HeaderComponent {
  constructor(
    public configService: ConfigService
  ) {
  }

  protected readonly environment = environment
}
